import React from "react"
import Button from "@mui/material/Button"
import { signOutUser } from "../auth"

const SignOutButton: React.FC = () => {
  const handleSignOut = async () => {
    try {
      await signOutUser()
      console.log("Signed out successfully")
      // Optionally, redirect the user or update the state/UI
    } catch (error) {
      console.error("Error signing out:", error)
    }
  }

  return (
    <Button variant="contained" color="secondary" onClick={handleSignOut}>
      Cerrar sesión
    </Button>
  )
}

export default SignOutButton
