import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import "./Loading.css"

const Loading = ({ isLoading }: { isLoading: boolean }) => {
  const [loadingText, setLoadingText] = useState(
    "Preparando todo para ti. Esto tomará solo unos momentos."
  )

  useEffect(() => {
    let i = 0
    const interval = setInterval(() => {
      const text = [
        "Estoy investigando acerca de tu pregunta... Por favor, espera un momento.",
        "¡Aguanta un poco más! Estoy obtiendo tu respuesta.",
        "Gracias por tu paciencia mientras cargo esta informacion para ti",
        "La información que solicitaste está en camino.",
      ]
      setLoadingText(text[i])
      if (i >= text.length) {
        setLoadingText("Cargando... ¡Gracias por tu espera!")
      }
      i++
    }, 7000)
    return () => clearInterval(interval)
  }, [isLoading])

  return (
    <div className="loading-container">
      <p className="loading-text">{loadingText}</p>
      <CircularProgress />
    </div>
  )
}

export default Loading
