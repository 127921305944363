import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react"
import { onAuthStateChanged, User, signOut } from "firebase/auth"
import { auth } from "../firebase-config"

// Define a type for the AuthProvider's props
interface AuthProviderProps {
  children: ReactNode // This tells TypeScript that `children` can be anything React can render
}

interface AuthContextType {
  user: User | null
  loading: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

const SESSION_TIMEOUT = 30 * 60 * 1000 // 30 minutes

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [lastActivity, setLastActivity] = useState<number>(Date.now())

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const handleActivity = () => {
      setLastActivity(Date.now())
    }

    // Update last activity on user actions
    window.addEventListener("click", handleActivity)
    window.addEventListener("keydown", handleActivity)

    // Check session timeout
    const interval = setInterval(() => {
      if (Date.now() - lastActivity > SESSION_TIMEOUT) {
        console.log("Session timed out")
        signOut(auth).catch(console.error)
      }
    }, 10000) // Check every 10 seconds

    // Cleanup
    return () => {
      window.removeEventListener("click", handleActivity)
      window.removeEventListener("keydown", handleActivity)
      clearInterval(interval)
    }
  }, [lastActivity])

  useEffect(() => {
    // Firebase Auth state listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, initialize or reset activity tracking
        setLastActivity(Date.now())
      } else {
        // User is signed out
      }
    })

    return () => unsubscribe()
  }, [])

  return (
    <AuthContext.Provider value={{ user, loading }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
