import { AuthProvider, useAuth } from './components/AuthContext';
import SignInForm from './components/SignInForm';
import MainApp from './components/MainApp';

const AuthenticatedApp: React.FC = () => {
  const { user } = useAuth();

  if (user) {
    return <MainApp />;
  } else {
    return <SignInForm />;
  }
};

function App() {
  return (
    <AuthProvider>
      <div>
        <AuthenticatedApp />
      </div>
    </AuthProvider>
  );
}

export default App;
