import React, { useState } from "react"
import { signIn } from "../auth" // Make sure this path is correct
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const SignInForm: React.FC = () => {
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [error, setError] = useState<string>("")

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError("")
    try {
      await signIn(email, password)
      console.log("You're logged in!")
      // Redirect or update UI
    } catch (error: any) {
      console.error("Error signing in:", error.message)
      setError(
        "No se pudo iniciar sesión. Verifique sus credenciales e inténtelo nuevamente."
      )
    }
  }

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: "auto", // margin auto for horizontal
        my: 4, // margin for top and bottom
        p: 3, // padding
        boxShadow: 3, // shadow
        borderRadius: 2, // border radius
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Iniciar sesión
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <TextField
          label="Correo electrónico"
          type="email"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Contraseña"
          type="password"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
        >
          Iniciar sesión
        </Button>
      </form>
    </Box>
  )
}

export default SignInForm
