// firebase-config.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDnT2rkfbCvGIAGl6iyieRHeBRlIECvnRM",
    authDomain: "llm-poc-410014.firebaseapp.com",
    projectId: "llm-poc-410014",
    storageBucket: "llm-poc-410014.appspot.com",
    messagingSenderId: "230090995837",
    appId: "1:230090995837:web:3c8bab4fee14b835f21914"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export

export const auth = getAuth(app);
